<template>
    <div id="app">
    <!-- 直接渲染 CustomForm 作为首页内容 -->
    <UserForm />
  </div>
</template>

<script>
import UserForm from './components/UserForm.vue'

export default {
  name: 'App',
  components: {
    UserForm
  }
}
</script>

<style>
#app {
  font-family: Poppins;
}
</style>
