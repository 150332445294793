<template>
	<div data-v-2cfee632="" class="login-wrapper part-time-login">
		<div data-v-2cfee632="" class="flex-col login-content">
			<div data-v-2cfee632="" class="alert alert-primary" role="alert">
				<img data-v-2cfee632="" src="@/assets/image/tip-icon.svg" alt="tip" class="tip-icon">
				<span data-v-2cfee632="" class="tip-text">Tips: This online job is reserved only for citizens of  {{form.countryCode}}</span>
			</div>
			<h3 data-v-2cfee632="" class="title">Apply today for your perfect remote job!</h3>
			<form @submit.prevent="submit" data-v-2cfee632="" action="" method="">
				<div data-v-2cfee632="" class="flex-col form-body">
					<div data-v-2cfee632="" class="flex  flex-1 form-container">
						<div data-v-9c88de9d="" data-v-2cfee632="" class="flex-col input-box position-relative w-100"
							prop="name" errmsg="" value="">
							<div data-v-9c88de9d="" class="input-content">
								<label data-v-9c88de9d="" for="Nome" class="gap-8 form-item-label"> Name</label>
								<div data-v-9c88de9d="" class="w-full position-relative">
									<!---->
									<!---->
									<!---->
									<input data-v-9c88de9d="" type="text" class="form-item"
										:style="nomeFlag ? 'border-color:red' : ''" id="Name" required=""
										autocomplete="off" maxlength="30" placeholder="your name"
										@blur="inputBlank('Name')">
								</div>
							</div>
							<!---->
							<div data-v-9c88de9d="" v-if="nomeFlag" class="error-msg">Please enter your name
							</div>
						</div>
						<div data-v-2cfee632="" class="flex w-100 age-form-item">
							<div data-v-2cfee632="" class="flex flex-column gender-container form-group">
								<label data-v-2cfee632="" class="form-label" style="font-size:0.9rem;">Gender</label>
								<div data-v-2cfee632="" class="dropdown" style="font-size:0.9rem;">
									<!-- 性别 -->
									<button data-v-2cfee632=""
										:class="text ? 'btn sex-select dropdown-toggle w-100 btn-value' : 'btn sex-select dropdown-toggle w-100'"
										type="button" v-on:click="show = true; sessoFlag = false">{{ text || 'Gender'
										}}</button>
									<ul data-v-2cfee632="" :class="show ? 'dropdown-menu show' : 'dropdown-menu hide'">
										<!-- 在这里改，性别在拿个值来代表，置为1 -->
										<li v-on:click="show = false; text = 'Male'" data-v-2cfee632=""
											class="dropdown-item">Male</li>
										<li v-on:click="show = false; text = 'Female'" data-v-2cfee632=""
											class="dropdown-item">Female</li>
									</ul>
								</div>
								<!---->
								<span v-if="sessoFlag" data-v-2cfee632="" class="error-msg">PPlease select your gender</span>
							</div>
							<div data-v-9c88de9d="" data-v-2cfee632=""
								class="flex-col input-box position-relative flex-1" prop="age" errmsg="" value="">
								<div data-v-9c88de9d="" class="input-content">
									<label data-v-9c88de9d="" for="Età" class="gap-8 form-item-label">Age</label>
									<div data-v-9c88de9d="" class="w-full position-relative">
										<!---->
										<!---->
										<!---->
										<input data-v-9c88de9d="" type="number" class="form-item" id="Age"
											:style="etaFlag ? 'border-color:red' : ''" required="" autocomplete="off"
											maxlength="3" placeholder="Age" @blur="inputBlank('Age')">
									</div>
								</div>
								<!---->
								<div v-if="etaFlag" data-v-9c88de9d="" class="error-msg">Please enter your age</div>
							</div>
						</div>
						<div data-v-9c88de9d="" data-v-2cfee632="" class="flex-col input-box position-relative w-100"
							prop="contact" errmsg="" code="61" value="">
							<div data-v-9c88de9d="" class="input-content">
								<label data-v-9c88de9d="" for="Numero di telefono con WhatsApp"
									class="gap-8 form-item-label">
									<img data-v-2cfee632="" src="@/assets/image/whatsapp.png" class="iphone-icon"
										alt="whatsapp"> Phone number with WhatsApp</label>
								<div data-v-9c88de9d="" class="w-full position-relative">
									<!---->
									<div data-v-9c88de9d="" class="prefix">
										<div data-v-2cfee632="" class="flex items-center contact-code">
											<div data-v-7ec48640="" data-v-2cfee632=""
												class="intl-tel-input allow-dropdown">
												<div data-v-7ec48640="" class="flag-container">
													<!-- ！这里才是真正的区号 -->
													<div data-v-7ec48640="" class="selected-flag w-selected"
														:title="title">
														<!-- 这里决定国旗 -->
														<span data-v-7ec48640=""
															:class="['iti-flag', shortName]"></span>
														<div data-v-7ec48640="" class="code" dir="ltr">
															<span data-v-7ec48640="">+{{ form.globalCode }}</span>
															<!---->
														</div>
													</div>


												</div>
											</div>
										</div>
									</div>
									<!---->
									<!---->
									<div data-v-2cfee632="" class="input-group">
										<span class="input-group-text input-group-prefix" id="basic-addon1">
											<div data-v-2cfee632="" class="flex items-center contact-code">
												<div data-v-7ec48640="" data-v-2cfee632=""
													class="intl-tel-input allow-dropdown">
													<div data-v-7ec48640="" class="flag-container">

														<div data-v-7ec48640="" class="selected-flag w-selected"
															title="United States: +1">
															<span data-v-7ec48640="" class="iti-flag us"></span>
															<div data-v-7ec48640="" class="code" dir="ltr">
																<span data-v-7ec48640="">+1</span>
																<!---->
															</div>
														</div>
													</div>
												</div>
											</div>
										</span>
										<!-- yanz -->
										<input type="tel" class="form-control" @blur="validateInput"
											placeholder="Please enter your correct WhatsApp number." id="Number"
											required="">
										<span class="input-group-text prepend"></span>
									</div>
								</div>
								<div v-if="phoneErr" data-v-9c88de9d="" class="error-msg">Please enter your correct WhatsApp number.</div>
							</div>
							<!---->
						</div>
						<div data-v-9c88de9d="" data-v-2cfee632="" class="flex-col input-box position-relative w-100"
							prop="email" errmsg="" value="">
							<div data-v-9c88de9d="" class="input-content">
								<label data-v-9c88de9d="" for="E-Mail" class="gap-8 form-item-label"> E-Mail</label>
								<div data-v-9c88de9d="" class="w-full position-relative">
									<!---->
									<!---->
									<!---->
									<input data-v-9c88de9d="" type="email" class="form-item" id="E-Mail"
										:style="emailFalg ? 'border-color:red' : ''" required="" autocomplete="off"
										placeholder="E-Mail" @blur="inputBlank('email')">
								</div>
							</div>
							<div v-if="emailFalg" data-v-9c88de9d="" class="error-msg">
								<font style="vertical-align: inherit;">
									<font style="vertical-align: inherit;">Please enter your email</font>
								</font>
							</div>
							<!---->
						</div>
					</div>
					<div data-v-2cfee632="" class="flex flex-wrap flex-column flex-md-row check-container">
						<div data-v-aee019c9="" data-v-2cfee632="" class="gap-8 from-item-check flex-center">
							<input v-model="choseFlag" data-v-aee019c9="" class="check-input" type="checkbox" id="checked-box"
								name="checked-box" :style="choseFlag ? '' : 'border-color:red'"
								@click="inputBlank('check')">
							<div data-v-aee019c9="" class="flex-1">
								<label data-v-aee019c9="" for="checked-box" class="">Yes, I am over 21 years old.</label>
							</div>
						</div>
					</div>
				</div>
				<button data-v-5b8baea9="" data-v-2cfee632="" class="basic-btn basic-btn- basic-btn- w-full"
					@click="sumbit" type="submit">
					<!---->Submit</button>
			</form>
			<div data-v-2cfee632="" class="note-box">
				<span data-v-2cfee632="" class="note-btn">Note</span>
				<span data-v-2cfee632="">Recruiters will contact candidates via WhatsApp. Please enter your WhatsApp number so we can reach you in the next steps of the application process.</span>
			</div>
		</div>
	</div>

</template>

<script>
/* eslint-disable */

import '@/assets/css/index-AHpSVa96.css';
import '@/assets/css/index-CAk8JJDA.css';
import '@/assets/css/index-CPKbq8OH.css';
import '@/assets/css/index-DOzYUoii.css';
import '@/assets/css/loginModal-DTSI0uTw.css';
import '@/assets/css/setTheme-qZZrSmVT.css';
import '@/assets/css/device-tnaPwCdr.css';

// import couData from '@/assets/data/country'

export default {
	name: 'UserForm',
	props: {
		msg: String
	},
	form: {
		userName: '',
		age: undefined,
		email: '',
		gender: undefined,
		globalCode: '',
		countryCode: '',
		phoneNumber: '',
		accountId: '6868121245',
		remark: '',
		routeId: 'SGmDWOJjEUJ'
	},
	//   data:()=>{
	// 	return {
	// 		
	// 		show:false
	// 	}
	//   },
	data() {
		return {
			show: false,
			text: "",
			phoneFocus: false,
			phoneSelect: false,
			phoneCode: {
				name: 'Argentina',
				code: '54',
			},
			searchInput: '',
			phoneSelectOpt: [],
			allSelectOpt: [
				{ name: 'United Arab Emirates', code: '971' },
				{ name: 'Argentina', code: '54' },
				{ name: 'Austria', code: '43' },
				{ name: 'Poland', code: '48' },
				{ name: 'Australia', code: '61' },
				{ name: 'Belgium', code: '32' },
				{ name: 'Brazil', code: '55' },
				{ name: 'Bahamas', code: '1' },
				{ name: 'Canada', code: '1' },
				{ name: 'Switzerland', code: '41' },
				{ name: 'Chile', code: '56' },
				{ name: 'China', code: '86' },
				{ name: 'Colombia', code: '57' },
				{ name: 'Cyprus', code: '357' },
				{ name: 'Cambodia', code: '855' },
				{ name: 'Czech Republic', code: '420' },
				{ name: 'Germany', code: '49' },
				{ name: 'Denmark', code: '45' },
				{ name: 'Ecuador', code: '593' },
				{ name: 'Estonia', code: '372' },
				{ name: 'Egypt', code: '20' },
				{ name: 'Spain', code: '34' },
				{ name: 'Finland', code: '358' },
				{ name: 'France', code: '33' },
				{ name: 'Greece', code: '30' },
				{ name: 'Croatia', code: '385' },
				{ name: 'Hungary', code: '36' },
				{ name: 'Hong Kong', code: '852' },
				{ name: 'Indonesia', code: '62' },
				{ name: 'Ireland', code: '353' },
				{ name: 'Israel', code: '972' },
				{ name: 'India', code: '91' },
				{ name: 'Iceland', code: '354' },
				{ name: 'Italy', code: '39' },
				{ name: 'Jamaica', code: '1' },
				{ name: 'Japan', code: '81' },
				{ name: 'Kenya', code: '254' },
				{ name: 'South Korea', code: '82' },
				{ name: 'Lithuania', code: '370' },
				{ name: 'Luxembourg', code: '352' },
				{ name: 'Latvia', code: '371' },
				{ name: 'Morocco', code: '212' },
				{ name: 'Mongolia', code: '976' },
				{ name: 'Mauritius', code: '230' },
				{ name: 'Maldives', code: '960' },
				{ name: 'Mexico', code: '52' },
				{ name: 'Malaysia', code: '60' },
				{ name: 'Netherlands', code: '31' },
				{ name: 'Norway', code: '47' },
				{ name: 'New Zealand', code: '64' },
				{ name: 'Panama', code: '507' },
				{ name: 'Peru', code: '51' },
				{ name: 'Philippines', code: '63' },
				{ name: 'Puerto Rico', code: '1' },
				{ name: 'Portugal', code: '351' },
				{ name: 'Qatar', code: '974' },
				{ name: 'Romania', code: '40' },
				{ name: 'Russia', code: '7' },
				{ name: 'Saudi Arabia', code: '966' },
				{ name: 'Seychelles', code: '248' },
				{ name: 'Sweden', code: '46' },
				{ name: 'Slovenia', code: '386' },
				{ name: 'Slovakia', code: '421' },
				{ name: 'Thailand', code: '66' },
				{ name: 'Tunisia', code: '216' },
				{ name: 'Turkey', code: '90' },
				{ name: 'Taiwan', code: '886' },
				{ name: 'United Kingdom', code: '44' },
				{ name: 'United States', code: '1' },
				{ name: 'Vietnam', code: '84' },
				{ name: 'South Africa', code: '27' },
			],
			phonePattern: '',
			form: {
				userName: '',
				age: undefined,
				email: '',
				gender: undefined,
				globalCode: '1',
				countryCode: '',
				phoneNumber: '',
				accountId: '6868121245',
				remark: '',
				routeId: '19225e02f53'
			},
			errors: {
				userName: false,
				phoneNumber: false,
				gender: false,
				email: false,
				age: false,
				emailFormat: false, // 新增的变量
				phoneError: false
			},
			shortName: 'us',
			title: 'United States: +1',
			phoneErr: false,
			nomeFlag: false,
			sessoFlag: false,
			etaFlag: false,
			emailFalg: false,
			choseFlag: false
		}
	},
	mounted() {
		this.getUserLocation();
		var height = document.documentElement.scrollHeight;
        window.parent.postMessage(height, '*');
		window.parent.postMessage(document.body.scrollHeight, '*');
	},
	methods: {
		inputBlank(item) {
			if (item == 'email') {
				const Email = document.getElementById('E-Mail').value
				if (Email != '') {
					this.emailFalg = false
				}
			}
			if (item == 'Name') {
				const Name = document.getElementById('Name').value
				if (Name != '') {
					this.nomeFlag = false
				}
			}
			if (item == 'Age') {
				const Age = document.getElementById('Age').value
				if (Age != '') {
					this.etaFlag = false
				}
			}
		},
		validatePhoneNumber(phoneNumber) {
			// 根据所选国家/地区的电话格式进行验证
			const patterns = {
				'United Arab Emirates': /^\d{9}$/,
				'Argentina': /^\d{10}$/,
				'Austria': /^\d{10}$/,
				'Poland': /^\d{9}$/,
				'Australia': /^\d{9}$/,
				'Belgium': /^\d{9}$/,
				'Brazil': /^\d{11}$/,
				'Bahamas': /^\d{10}$/,
				'Canada': /^\d{10}$/,
				'Switzerland': /^\d{9}$/,
				'Chile': /^\d{9}$/,
				'China': /^\d{11}$/,
				'Colombia': /^\d{10}$/,
				'Cyprus': /^\d{8}$/,
				'Cambodia': /^\d{9}$/,
				'Czech Republic': /^\d{9}$/,
				'Germany': /^\d{10,11}$/,
				'Denmark': /^\d{8}$/,
				'Ecuador': /^\d{9}$/,
				'Estonia': /^\d{7,8}$/,
				'Egypt': /^\d{10}$/,
				'Spain': /^\d{9}$/,
				'Finland': /^\d{9}$/,
				'France': /^\d{9}$/,
				'Greece': /^\d{10}$/,
				'Croatia': /^\d{9}$/,
				'Hungary': /^\d{9}$/,
				'Hong Kong': /^\d{8}$/,
				'Indonesia': /^\d{10,11}$/,
				'Ireland': /^\d{9}$/,
				'Israel': /^\d{9}$/,
				'India': /^\d{10}$/,
				'Iceland': /^\d{7}$/,
				'Italy': /^\d{9,10}$/,
				'Jamaica': /^\d{10}$/,
				'Japan': /^\d{10}$/,
				'Kenya': /^\d{9}$/,
				'South Korea': /^\d{10,11}$/,
				'Lithuania': /^\d{8}$/,
				'Luxembourg': /^\d{9}$/,
				'Latvia': /^\d{8}$/,
				'Morocco': /^\d{9}$/,
				'Mongolia': /^\d{8}$/,
				'Mauritius': /^\d{8}$/,
				'Maldives': /^\d{7}$/,
				'Mexico': /^\d{10}$/,
				'Malaysia': /^\d{9,10}$/,
				'Netherlands': /^\d{9}$/,
				'Norway': /^\d{8}$/,
				'New Zealand': /^\d{8,10}$/,
				'Panama': /^\d{8}$/,
				'Peru': /^\d{9}$/,
				'Philippines': /^\d{10}$/,
				'Puerto Rico': /^\d{10}$/,
				'Portugal': /^\d{9}$/,
				'Qatar': /^\d{8}$/,
				'Romania': /^\d{10}$/,
				'Russia': /^\d{10}$/,
				'Saudi Arabia': /^\d{9,10}$/,
				'Seychelles': /^\d{7}$/,
				'Sweden': /^\d{7,10}$/,
				'Slovenia': /^\d{9}$/,
				'Slovakia': /^\d{9}$/,
				'Thailand': /^\d{9}$/,
				'Tunisia': /^\d{8}$/,
				'Turkey': /^\d{10}$/,
				'Taiwan': /^\d{9}$/,
				'United Kingdom': /^\d{10}$/,
				'United States': /^\d{10}$/,
				'Vietnam': /^\d{9,10}$/,
				'South Africa': /^\d{9}$/,
			};
			const pattern = patterns[this.form.countryCode] || /.*/; // 默认匹配所有
			return pattern.test(phoneNumber);
		},
		validateEmail(email) {
			// 简单的邮箱正则表达式验证
			const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			return emailPattern.test(email);
		},
		validateInput() {
			const Number = document.getElementById('Number').value
			this.phoneErr = !this.validatePhoneNumber(Number)
		},
		async sumbit() {
			event.preventDefault();
			const Name = document.getElementById('Name').value
			const Age = document.getElementById('Age').value
			const Number = document.getElementById('Number').value
			const Email = document.getElementById('E-Mail').value
			const Gender = this.text
			const chose = document.getElementById('checked-box').value

			// 验证
			let flag = true
			if (Name == '') {
				this.nomeFlag = true
				flag = false
			} else {
				this.nomeFlag = false
			}
			if (Age == '') {
				this.etaFlag = true
				flag = false
			} else {
				this.etaFlag = false
			}
			if (Gender == '') {
				this.sessoFlag = true
				flag = false
			} else {
				this.sessoFlag = false
			}
			if (Age == '') {
				this.etaFlag = true
				flag = false
			} else {
				this.etaFlag = false
			}
			if (Email == '' || this.validateEmail(Email.trim())) {
				this.emailFalg = true
				flag = false
			} else {
				this.emailFalg = false
			}
			if (Number == '' || this.validateInput(Number.trim())) {
				this.phoneErr = true
				flag = false
			} else {
				this.phoneErr = false
			}
			if (!this.choseFlag) {
				flag = false
			} 
			if (!flag) {
				return 0
			} 
			try {
				let routeId = this.form.routeId;
				let globalCode =this.form.globalCode;
				const response = await fetch("http://localhost:8888/applyList/getApplyListForm", {
					method: "POST", // *GET, POST, PUT, DELETE, etc.
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						Name,
						Age,
						Number,
						Email,
						Gender,
						routeId,
						globalCode
					}), // body data type must match "Content-Type" header
				});

				const data = await response.json();

				return false;
			} catch (err) {
				return false;
			}
		},
		async getUserLocation() {
			try {
				// 获取用户的IP地址
				const ipResponse = await fetch('https://api.ipify.org?format=json');
				const ipData = await ipResponse.json();
				const ip = ipData.ip;
				console.log(ip)

				// 使用IP地址获取地理位置
				let url = 'https://ipapi.co/' + ip + '/json'
				const geoResponse = await fetch(url);
				const geoData = await geoResponse.json();
				const country_code = geoData.country_code;
				const country = geoData.country_name;
				const countryCode = geoData.country_calling_code.replace('+', '');
				this.form.globalCode = countryCode;
				const selectedCountry = this.allSelectOpt?.find(item => item.name === country);
				if (selectedCountry) {
					this.phoneCode = { name: selectedCountry.name, code: countryCode };
					console.log(this.form.globalCode)
					this.form.countryCode = selectedCountry.name;
					// 区号和简称相对应
					this.shortName = country_code.toLowerCase()
					this.title = selectedCountry.name + ': +' + countryCode
				}
			} catch (error) {
			}
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
body {
	overflow-y: hidden;
	background-color: #fff;
}
.red {
	border-color: red;
}
</style>
